import React, { useState, useEffect, useRef } from 'react';

const CheckoutEmail = props => {

	const form = useRef();
	const { setEmailDetails, subscribeNewsletter, setSubscribeNewsletter } =  props

	const [ email, setEmail ] = useState( '' )

	useEffect(()=>{
		setEmailDetails({
			contactEmail: email
		})
	}, [ email, setEmailDetails ])

	return (
		<div className="checkout-address">
			<form className="form" ref={ form }>
				<div className="form__input-container">
					<label className="form__label"  htmlFor="email">Email</label>
					<input
						className="form__input form__input--blue"
						type="text"
						name="email"
						id="email"
						value={ email }
						placeholder="Enter email address"
						onChange={ e => { setEmail( e.target.value ) } }
						required />
				</div>
				<div className="form__input-container">
						<label className="form__checkbox-label" htmlFor="subscribeToNewsletter">
						<input
							className="form__checkbox-input"
							type="checkbox"
							id="subscribeToNewsletter"
							defaultChecked={ subscribeNewsletter }
							onChange={ () => { setSubscribeNewsletter( !subscribeNewsletter ) } }/>
							<div className="form__checkbox-checkmark"></div>
						<span className="form__checkbox-text">Subscribe to Newsletter</span>
					</label>
				</div>
			</form>
		</div>
	)
}

export default CheckoutEmail;